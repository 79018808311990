export const getYearWiseAppConfig = (item, params) => {
    if (item.pubYear === params.pubYear) {
      return true;
    }
    if (item.brand === 'vogue') {
        if (Number(params.pubYear) <= 2021 && item.pubYear === '2021') {
          return true;
        }
        if (Number(params.pubYear) >= 2024 && item.pubYear === '2024') {
          return true;
        }
    }

    if (item.brand === 'glamour') {
        if (Number(params.pubYear) <= 2022 && item.pubYear === '2022') {
            return true;
          }
          if (Number(params.pubYear) >= 2024 && item.pubYear === '2024') {
            return true;
          }
    }

    if (item.brand === 'vanityfair') {
        if (Number(params.pubYear) <= 2022 && item.pubYear === '2022') {
            return true;
          }
          if (Number(params.pubYear) >= 2024 && item.pubYear === '2024') {
            return true;
          }
    }
    
    if (item.brand === 'gq') {
        if (Number(params.pubYear) <= 2021 && item.pubYear === '2021') {
            return true;
          }
          if (Number(params.pubYear) >= 2024 && item.pubYear === '2024') {
            return true;
          }   
    }
    return false;
  };